import Login from '../Components/Login';
import styled from 'styled-components'

function FrontScreen() {
    return (
        <Container >
            <img src='/assets/images/logo.png' style={{ maxWidth:'10vw'}} alt='logo' />
            <h3>TSBF MICROFINANCE</h3>
            <p><i>'Building With You'</i></p>
            <Login />
        </Container>    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 60vw;
  background-color: #61dafb;
  justify-content: center;

  @media screen and(max-width: 580px) {
  body {
      display:none;
  }
}
`


export default FrontScreen;
