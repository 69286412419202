import { useContext, useEffect, useState } from 'react'
import Axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { Store } from '../../Store';

const PortalRegistration = (props) => {

    useEffect(
        () => {
            document.title = 'Portal Resgistration';
        }, []
    )

    const [role, setRole] = useState('');
    const [staffID, setStaffID] = useState('');
    const [password, setPassword] = useState('');
    const [RegBy, setRegBy] = useState('');
    const [adminPassword, setAdminPassword] = useState('');

    const { dispatch: ctxDispatch } = useContext(Store);


    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (password.length < 8) {
                toast.info('Password must be at least 8 characters')
            }
            else {
                const data = await Axios.post('users/signup', {
                    role,
                    staffID,
                    password,
                    RegBy,
                    adminPassword
                });

                if (data.status === 204) {
                    toast.info('User exits already, kindly proceed log in');
                    return;
                }
                else if (data.status === 201) {
                    toast.success('Registration successful!, Please wait while you are logged in')
                    localStorage.setItem('userInfo', JSON.stringify(data));
                }
            }

        } catch (err) {
            toast.error(err);
        }
    };


    return (
        <div>
            <Container>
                <form onSubmit={submitHandler} >
                    <h2>Portal Registration</h2>
                    <div className="input-container">
                        <i className="fa fa-cogs icon" />
                        <select name="Role" data-use-select2="" data-placeholder="Select" required 
                            id="role" aria-describedby="10fdi1-abide-error" tabIndex="-1"
                            aria-hidden="true" data-select2-id="select2-data-id_country_of_residence" onChange={(e) => setRole(e.target.value)}>
                            <option value="" >Role</option>
                            <option value="Admin">Admin</option>
                            <option value="Assistant Admin">Assistant Admin</option>
                            <option value="Top Manager">Top Manager</option>
                            <option value="Middle Manager">Middle Manager</option>
                            <option value="Line Manager">Line Manager</option>
                            <option value="CSR">Customer Service</option>
                            <option value="Cashier">Cashier</option>
                            <option value="Loan Officer">Loan Officer</option>
                            <option value="Account Officer">Account Officer</option>
                            <option value="Merchant">Merchant</option>
                            <option value="Agent">Agent</option>
                        </select>
                        
                    </div>
                    <div className="input-container">
                        <i className="fa fa-user icon" />
                        <input className="input-field" type="text" placeholder="Staff ID" name="staffID" onChange={(e) => setStaffID(e.target.value)} required /> 
                        <small className='text-danger fw-bold'><i>Please confirm</i></small>
                        <input className="input-field" type="text" placeholder="Staff selected:" name="Registered By" onChange={(e) => setRegBy(e.target.value)} required disabled />

                    </div>
                    <div className="input-container">
                        <i className="fa fa-key icon" />
                        <input className="input-field" type="password" placeholder="Assign Password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <small className='text-danger fw-bold'>ADMIN SECTION: </small>
                    <div className="input-container">
                        <i className="fa fa-user icon" />
                        <input className="input-field" type="text" placeholder="Registered By:" name="Registered By" onChange={(e) => setRegBy(e.target.value)} required disabled />
                    </div>
                    <div className="input-container">
                        <i className="fa fa-key icon" />
                        <input className="input-field" type="password" placeholder="Confirm your password as an administrator" name="password" onChange={(e) => setAdminPassword(e.target.value)} required />
                    </div>
                    <button type="submit" className="btn btn-primary">Register</button> <br />
                </form>
            </Container>
        </div>
    )
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 60vw;
  background-color: cyan;
  justify-content: center;

h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}
.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.btn {
  background-color: dodgerblue;
  color: white;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
}

form {
    width: 60vw;
}

@media screen and (min-width: 581px) and (max-width: 980px) {
        form {
        width: 90vw;
    }
}

   @media screen and (max-width: 580px) {
        .extra {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            justify-content: space-around;
        }

        form {
            width: 90vw;
        }
}
`

export default PortalRegistration

/*


  @media screen and(max-width: 580px) {
  body {
      display:none;
  }
}
*/