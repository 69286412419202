import './CreateAccount.css'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Store } from '../../Store'
import Axios from 'axios';
import { toast } from 'react-toastify';


const CreateAccount = (props) => {

    const navigate = useNavigate()
    useEffect(
        () => {
            document.title = 'Register';
        }, []
    )

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [batch, setBatch] = useState('');
    const [country, setCountry] = useState('');
    const [fieldOfStudy, setFieldOfStudy] = useState('');
    const [language, setLanguage] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userType, setUserType] = useState('')
    const [gender, setGender] = useState('')


    const { dispatch: ctxDispatch } = useContext(Store);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (password !== confirmPassword) {
                toast.error('Passwords do not match');
            } else if (password.length < 8) {
                toast.info('Password must be at least 8 characters')
            }
            else {
                const data = await Axios.post('users/signup', {
                    firstname,
                    lastname,
                    email,
                    batch,
                    country,
                    fieldOfStudy,
                    language,
                    password,
                    userType,
                    gender
                });

                if (data.status === 204) {
                    toast.info('User exits already, kindly proceed log in');
                    return;
                }
                else if (data.status === 201) {
                    toast.success('Registration successful!, Please wait while you are logged in')
                    ctxDispatch({ type: 'USER_SIGNIN', payload: data });
                    localStorage.setItem('userInfo', JSON.stringify(data));
                    navigate('/dashboard');
                }
            }

        } catch (err) {
            toast.error(err);
        }
    };


    return (
        <div style={{ marginTop: '0.5rem', minHeight: '80vh' }}>
            <form onSubmit={submitHandler} style={{ maxWidth: 500, margin: 'auto' }}>
                <h2 style={{ textAlign: 'center' }}>Register Here!</h2>
                <div className='justify-content-center'>
                    <label><b>Account Type?</b> </label> &nbsp;
                    Student <input type='radio' name='userType' value='Student' required onChange={(e) => setUserType(e.target.value)} /> &nbsp;
                    Alumini <input type='radio' name='userType' value='Alumini' required onChange={(e) => setUserType(e.target.value)} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <label><b>Gender:</b></label>&nbsp;
                    M <input type='radio' name='gender' value='Male' required onChange={(e) => setGender(e.target.value)} /> &nbsp;
                    F <input type='radio' name='gender' value='Female' required onChange={(e) => setGender(e.target.value)} />

                </div>


                <div className="input-container">
                    <i className="fa fa-user icon" />
                    <input className="input-field" type="text" placeholder="First Name" name="fname" onChange={(e) => setFirstName(e.target.value)} required /> &nbsp; &nbsp;
                    <input className="input-field" type="text" placeholder="Last Name" name="lname" onChange={(e) => setLastName(e.target.value)} required />
                </div>
                <div className="input-container">
                    <i className="fa fa-envelope icon" />
                    <input className="input-field" type="text" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="input-container">
                    <i className="fa fa-user icon" />
                    <input className="input-field" type="text" placeholder="Batch Number" name="batch" onChange={(e) => setBatch(e.target.value)} required /> &nbsp; &nbsp;
                    <input className="input-field" type="text" placeholder="Field of Study( eg: Web Developer)" name="fieldOfStudy" onChange={(e) => setFieldOfStudy(e.target.value)} required />
                </div>
                <div className="input-container">
                    <i className="fa fa-user icon" />
                    <select name="country_of_residence" data-use-select2="" data-placeholder="Select" required defaultValue='Ghana'
                        id="id_country_of_residence" aria-describedby="10fdi1-abide-error" tabIndex="-1"
                        aria-hidden="true" data-select2-id="select2-data-id_country_of_residence" onChange={(e) => setCountry(e.target.value)}>

                        <option value="">Country of Residence</option>

                        <option value="Afghanistan">Afghanistan</option>

                        <option value="Åland Islands">Åland Islands</option>

                        <option value="Albania">Albania</option>

                        <option value="Algeria">Algeria</option>

                        <option value="American Samoa">American Samoa</option>

                        <option value="Andorra">Andorra</option>

                        <option value="Angola">Angola</option>

                        <option value="Anguilla">Anguilla</option>

                        <option value="Antarctica">Antarctica</option>

                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>

                        <option value="Argentina">Argentina</option>

                        <option value="Armenia">Armenia</option>

                        <option value="Aruba">Aruba</option>

                        <option value="Australia">Australia</option>

                        <option value="Austria">Austria</option>

                        <option value="Azerbaijan">Azerbaijan</option>

                        <option value="Bahamas">Bahamas</option>

                        <option value="Bahrain">Bahrain</option>

                        <option value="Bangladesh">Bangladesh</option>

                        <option value="Barbados">Barbados</option>

                        <option value="Belarus">Belarus</option>

                        <option value="Belgium">Belgium</option>

                        <option value="Belize">Belize</option>

                        <option value="Benin">Benin</option>

                        <option value="Bermuda">Bermuda</option>

                        <option value="Bhutan">Bhutan</option>

                        <option value="Bolivia">Bolivia</option>

                        <option value="Bonaire, Sint Eustatius & Saba">Bonaire, Sint Eustatius & Saba</option>

                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>

                        <option value="Botswana">Botswana</option>

                        <option value="Bouvet Island">Bouvet Island</option>

                        <option value="Brazil">Brazil</option>

                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>

                        <option value="Brunei">Brunei</option>

                        <option value="Bulgaria">Bulgaria</option>

                        <option value="Burkina Faso">Burkina Faso</option>

                        <option value="Burundi">Burundi</option>

                        <option value="Cabo Verde">Cabo Verde</option>

                        <option value="Cambodia">Cambodia</option>

                        <option value="Cameroon">Cameroon</option>

                        <option value="Canada">Canada</option>

                        <option value="Cayman Islands">Cayman Islands</option>

                        <option value="Central African Republic">Central African Republic</option>

                        <option value="Chad">Chad</option>

                        <option value="Chile">Chile</option>

                        <option value="China">China</option>

                        <option value="Christmas Island">Christmas Island</option>

                        <option value="Cocos Islands">Cocos Islands</option>

                        <option value="Colombia">Colombia</option>

                        <option value="Comoros">Comoros</option>

                        <option value="Congo">Congo</option>

                        <option value="DR Congo">DR Congo</option>

                        <option value="Cook Islands">Cook Islands</option>

                        <option value="Costa Rica">Costa Rica</option>

                        <option value="Côte d'Ivoire">Côte d'Ivoire</option>

                        <option value="Croatia">Croatia</option>

                        <option value="Cuba">Cuba</option>

                        <option value="Curaçao">Curaçao</option>

                        <option value="Cyprus">Cyprus</option>

                        <option value="Czechia">Czechia</option>

                        <option value="Denmark">Denmark</option>

                        <option value="Djibouti">Djibouti</option>

                        <option value="Dominica">Dominica</option>

                        <option value="Dominican Republic">Dominican Republic</option>

                        <option value="Ecuador">Ecuador</option>

                        <option value="Egypt">Egypt</option>

                        <option value="El Salvador">El Salvador</option>

                        <option value="Equatorial Guinea">Equatorial Guinea</option>

                        <option value="Eritrea">Eritrea</option>

                        <option value="Estonia">Estonia</option>

                        <option value="Eswatini">Eswatini</option>

                        <option value="Ethiopia">Ethiopia</option>

                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>

                        <option value="Faroe Islands">Faroe Islands</option>

                        <option value="Fiji">Fiji</option>

                        <option value="Finland">Finland</option>

                        <option value="France">France</option>

                        <option value="French Guiana">French Guiana</option>

                        <option value="French Polynesia">French Polynesia</option>

                        <option value="French Southern Territories">French Southern Territories</option>

                        <option value="Gabon">Gabon</option>

                        <option value="Gambia">Gambia</option>

                        <option value="Georgia">Georgia</option>

                        <option value="Germany">Germany</option>

                        <option value="Ghana">Ghana</option>

                        <option value="Gibraltar">Gibraltar</option>

                        <option value="Greece">Greece</option>

                        <option value="Greenland">Greenland</option>

                        <option value="Grenada">Grenada</option>

                        <option value="Guadeloupe">Guadeloupe</option>

                        <option value="Guam">Guam</option>

                        <option value="Guatemala">Guatemala</option>

                        <option value="Guernsey">Guernsey</option>

                        <option value="Guinea">Guinea</option>

                        <option value="Guinea-Bissau">Guinea-Bissau</option>

                        <option value="Guyana">Guyana</option>

                        <option value="Haiti">Haiti</option>

                        <option value="HM">Heard and McDonald Islands</option>

                        <option value="Holy See">Holy See</option>

                        <option value="Honduras">Honduras</option>

                        <option value="Hong Kong">Hong Kong</option>

                        <option value="Hungary">Hungary</option>

                        <option value="Iceland">Iceland</option>

                        <option value="India">India</option>

                        <option value="Indonesia">Indonesia</option>

                        <option value="Iran">Iran</option>

                        <option value="Iraq">Iraq</option>

                        <option value="Ireland">Ireland</option>

                        <option value="Isle of Man">Isle of Man</option>

                        <option value="Israel">Israel</option>

                        <option value="Italy">Italy</option>

                        <option value="Jamaica">Jamaica</option>

                        <option value="Japan">Japan</option>

                        <option value="Jersey">Jersey</option>

                        <option value="Jordan">Jordan</option>

                        <option value="Kazakhstan">Kazakhstan</option>

                        <option value="Kenya">Kenya</option>

                        <option value="Kiribati">Kiribati</option>

                        <option value="Kuwait">Kuwait</option>

                        <option value="Kyrgyzstan">Kyrgyzstan</option>

                        <option value="Laos">Laos</option>

                        <option value="Latvia">Latvia</option>

                        <option value="Lebanon">Lebanon</option>

                        <option value="Lesotho">Lesotho</option>

                        <option value="Liberia">Liberia</option>

                        <option value="Libya">Libya</option>

                        <option value="Liechtenstein">Liechtenstein</option>

                        <option value="Lithuania">Lithuania</option>

                        <option value="Luxembourg">Luxembourg</option>

                        <option value="Macao">Macao</option>

                        <option value="Madagascar">Madagascar</option>

                        <option value="Malawi">Malawi</option>

                        <option value="Malaysia">Malaysia</option>

                        <option value="Maldives">Maldives</option>

                        <option value="Mali">Mali</option>

                        <option value="Malta">Malta</option>

                        <option value="Marshall Islands">Marshall Islands</option>

                        <option value="Martinique">Martinique</option>

                        <option value="Mauritania">Mauritania</option>

                        <option value="Mauritius">Mauritius</option>

                        <option value="Mayotte">Mayotte</option>

                        <option value="Mexico">Mexico</option>

                        <option value="States of Micronesia">States of Micronesia</option>

                        <option value="Moldova">Moldova</option>

                        <option value="Monaco">Monaco</option>

                        <option value="Mongolia">Mongolia</option>

                        <option value="Montenegro">Montenegro</option>

                        <option value="Montserrat">Montserrat</option>

                        <option value="Morocco">Morocco</option>

                        <option value="Mozambique">Mozambique</option>

                        <option value="Myanmar">Myanmar</option>

                        <option value="Namibia">Namibia</option>

                        <option value="Nauru">Nauru</option>

                        <option value="Nepal">Nepal</option>

                        <option value="Netherlands">Netherlands</option>

                        <option value="New Caledonia">New Caledonia</option>

                        <option value="New Zealand">New Zealand</option>

                        <option value="Nicaragua">Nicaragua</option>

                        <option value="Niger">Niger</option>

                        <option value="Nigeria">Nigeria</option>

                        <option value="Niue">Niue</option>

                        <option value="Norfolk Island">Norfolk Island</option>

                        <option value="North Korea">North Korea</option>

                        <option value="North Macedonia">North Macedonia</option>

                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>

                        <option value="Norway">Norway</option>

                        <option value="Oman">Oman</option>

                        <option value="Pakistan">Pakistan</option>

                        <option value="Palau">Palau</option>

                        <option value="Palestine">Palestine (West Bank/Gaza)</option>

                        <option value="Panama">Panama</option>

                        <option value="Papua New Guinea">Papua New Guinea</option>

                        <option value="Paraguay">Paraguay</option>

                        <option value="Peru">Peru</option>

                        <option value="Philippines">Philippines</option>

                        <option value="Pitcairn">Pitcairn</option>

                        <option value="Poland">Poland</option>

                        <option value="Portugal">Portugal</option>

                        <option value="Puerto Rico">Puerto Rico</option>

                        <option value="Qatar" >Qatar</option>

                        <option value="Réunion">Réunion</option>

                        <option value="Romania">Romania</option>

                        <option value="Russia">Russia</option>

                        <option value="Rwanda">Rwanda</option>

                        <option value="Saint Barthélemy">Saint Barthélemy</option>

                        <option value="Saint Helena">Saint Helena</option>

                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>

                        <option value="Saint Lucia">Saint Lucia</option>

                        <option value="Saint Martin (French part)">Saint Martin (French part)</option>

                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>

                        <option value="St Vincent and the Grenadines">St Vincent and the Grenadines</option>

                        <option value="Samoa">Samoa</option>

                        <option value="San Marino">San Marino</option>

                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>

                        <option value="Saudi Arabia">Saudi Arabia</option>

                        <option value="Senegal">Senegal</option>

                        <option value="Serbia">Serbia</option>

                        <option value="Seychelles">Seychelles</option>

                        <option value="Sierra Leone">Sierra Leone</option>

                        <option value="Singapore">Singapore</option>

                        <option value="Sint Maarten">Sint Maarten</option>

                        <option value="Slovakia">Slovakia</option>

                        <option value="Slovenia">Slovenia</option>

                        <option value="Solomon Islands">Solomon Islands</option>

                        <option value="Somalia">Somalia</option>

                        <option value="South Africa">South Africa</option>

                        <option value="South Georgia">South Georgia </option>

                        <option value="South Korea">South Korea</option>

                        <option value="South Sudan">South Sudan</option>

                        <option value="Spain">Spain</option>

                        <option value="Sri Lanka">Sri Lanka</option>

                        <option value="Sudan">Sudan</option>

                        <option value="Suriname">Suriname</option>

                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>

                        <option value="Sweden">Sweden</option>

                        <option value="Switzerland">Switzerland</option>

                        <option value="Syria">Syria</option>

                        <option value="Taiwan">Taiwan</option>

                        <option value="Tajikistan">Tajikistan</option>

                        <option value="Tanzania">Tanzania</option>

                        <option value="Thailand">Thailand</option>

                        <option value="Timor-Leste">Timor-Leste</option>

                        <option value="Togo">Togo</option>

                        <option value="Tokelau">Tokelau</option>

                        <option value="Tonga">Tonga</option>

                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>

                        <option value="Tunisia">Tunisia</option>

                        <option value="Turkey">Turkey</option>

                        <option value="Turkmenistan">Turkmenistan</option>

                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>

                        <option value="Tuvalu">Tuvalu</option>

                        <option value="Uganda">Uganda</option>

                        <option value="Ukraine">Ukraine</option>

                        <option value="United Arab Emirates">United Arab Emirates</option>

                        <option value="United Kingdom">United Kingdom</option>

                        <option value="United States Minor">United States Minor</option>

                        <option value="United States of America">United States of America</option>

                        <option value="Uruguay">Uruguay</option>

                        <option value="Uzbekistan">Uzbekistan</option>

                        <option value="Vanuatu">Vanuatu</option>

                        <option value="Venezuela">Venezuela</option>

                        <option value="Vietnam">Vietnam</option>

                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>

                        <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>

                        <option value="Wallis and Futuna">Wallis and Futuna</option>

                        <option value="Western Sahara">Western Sahara</option>

                        <option value="Yemen">Yemen</option>

                        <option value="Zambia">Zambia</option>

                        <option value="Zimbabwe">Zimbabwe</option>

                    </select>

                </div>
                <div className="input-container">
                    <i className="fa fa-key icon" />
                    <input className="input-field" type="password" placeholder="Password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <div className="input-container">
                    <i className="fa fa-key icon" />
                    <input className="input-field" type="password" placeholder="Confirm Password" name="confirmPassword" onChange={(e) => setConfirmPassword(e.target.value)} required />
                </div>
                <button type="submit" className="btn btn-primary">Register</button> <br />
                <div className='text-center' onClick={() => navigate('/')}><i>Already have an account?</i> <b>Login</b></div>

            </form>
        </div>
    )
}

export default CreateAccount