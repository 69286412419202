import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function ForgottenPassword() {
    useEffect(
        () => {
            document.title = 'Forgotten Password - TSBF MFin';
        }, []
    )

    const [email, setEmail] = useState('');

    
    const handleSubmit = (e) =>{
        e.preventDefault()
    }

    return (
        <div>
            <Container>
                <div className="heading">
                    <h3>FORGOTTEN PASSWORD</h3>
                </div>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <i className="fa fa-envelope icon" />
                    <input className="input-field" type="text" placeholder="Enter Your Registered Email" name="email" onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
                    <div className="text-center fw-bold fs-50px pt-3">Back to <Link to="/">Login</Link></div>
            </form>
            </Container>
        </div>
    )
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 60vw;
  background-color: #61dafb;
  justify-content: center;

h3 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.input-container {
  display: -ms-flexbox; 
display: flex;
width: 100 %;
margin - bottom: 15px;
}

.icon {
    padding: 10px;
    background: dodgerblue;
    color: white;
    min - width: 50px;
    text - align: center;
}

.input - field {
    width: 100 %;
    padding: 10px;
    outline: none;
}

.input - field:focus {
    border: 2px solid dodgerblue;
}

.btn {
    background - color: dodgerblue;
    color: white;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 100 %;
    opacity: 0.9;
}

.btn:hover {
    opacity: 1;
}

form {
    width: 50vw;
}

@media screen and (min-width: 581px) and (max-width: 980px) {
        form {
        width: 90vw;
    }
}
@media screen and (max-width: 580px) {
        form {
        width: 90vw;
    }
}
`

export default ForgottenPassword




