import React from 'react'

const FooterBase = () => {
  return (
      <div>
          <footer className='fixed-bottom text-center text-light bg-dark'>All Rights Reserved @ <i>TSBF MICROFINANCE 2022</i> </footer>
    </div>
  )
}

export default FooterBase