import React, { useContext, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Store } from '../Store';
import Axios from 'axios';
//import logo from './assets/images/logo.png'
import { Link } from 'react-router-dom';
import Bar2 from './Bar2'

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;


  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    window.location.href = '/signin';
  };

  const currDateNTime = new Date().toUTCString()

  return (
    <>
        <Navbar bg="info" variant="light" expand="lg">
          <Container>
            <LinkContainer to="/dashboard">
            <Navbar.Brand><img src='/assets/images/logo.png' alt='' style={{width:'10vw', height:'10vh'}}/></Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            

            <Nav className="ms-auto d-flex flex-column text-white">
              <span >TSBF MicroFinance</span>
               <p className='text-muted' >JERRY BABATUNDE is logged in as Administrator</p>
              <span >System Date: </span>
              <span>Current Date: {currDateNTime}</span> 
              {/* <Link className="nav-link" to="/signin"> Sign In  </Link>  */}
            </Nav>
            
            </Navbar.Collapse>
          </Container>
        </Navbar>
                  <Bar2/>
    </>

  );
}

export default App;






/*
import React, { useContext } from 'react'

import { Link,  useNavigate } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Store } from '../Store'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import {toast} from 'react-toastify'


function NavigationBar() {
  const navigate = useNavigate()


  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('paymentMethod');
    navigate('/');
    toast.success('Logout successful!')
  };
 


  return (
    <Nav className="navbar navbar-expand-lg bg-light border-bottom sticky-top">
      <div className="container-fluid m-1 mb-lg-0">

        <Link className="navbar-brand ms-5" to='/'>
          <img className="rounded-pill" src='/assets/images/logo.png' alt="edureka Logo" style={{ width: '60px' }} />
        </Link>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" aria-controls="basic-navbar-nav"  data-bs-target="#collapsibleNavbar">
          <span style={{ color: 'black' }} className="navbar-toggler-icon"> </span>
        </button> *
        <div className='mx-auto'>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item text-primary">
                {userInfo ? (
                 <Link className="nav-link" to={'/search-student'}>Find Students</Link>  
              )
                 : (
                   <Link className="nav-link" to={'/signin?redirect=/search-student'}>Find Students</Link> 
               )
          }   
            </li>
            <li className="nav-item">
               {userInfo ? (
                <Link className="nav-link" to='/search-alumini'>Find Alumini</Link>
              ) : (
                <Link className="nav-link" to={'/signin?redirect=/search-alumini'}>Find Alumini</Link>
              )} 
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/faqs">Q/A</Link>
            </li>

          </ul>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {userInfo ?
              (
                <>
                    {userInfo.isProfilePictureSet === false && userInfo.gender === 'Male' ? (
                    <img src="/assets/images/profilepic.png" alt={userInfo.firstname} style={{ minHeight: '100%', maxWidth: '60px', borderRadius: '50%' }} />)
                    : userInfo.isProfilePictureSet === false && userInfo.gender === 'Female' ? (
                      <img src="/assets/images/profilepicg.jpg" alt={userInfo.firstname} style={{ minHeight: '100%', maxWidth: '60px', borderRadius: '50%' }} />)
                      :
                      (<img className='img-large' src={userInfo.profilePicture} alt={userInfo.name} style={{ minHeight: '100%', maxWidth: '100%', borderRadius: '50%' }} />)
                  }
                  <div className='align-middle'> {userInfo.firstname}</div>
                  <>
                    <NavDropdown id="basic-nav-dropdown">
                      <LinkContainer to="/profile">
                        <NavDropdown.Item>User Profile</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="/dashboard">
                        <NavDropdown.Item>Dashboard</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="/myconversations">
                        <NavDropdown.Item>My Conversations</NavDropdown.Item>
                      </LinkContainer>

                      <NavDropdown.Divider />

                      <NavDropdown.Item className="dropdown-item" onClick={signoutHandler}>
                        Sign Out
                      </NavDropdown.Item>
                    </NavDropdown>
                  </></>
              ) : (

              )}
          </ul>
        </Navbar.Collapse>
      </div>

      <Modal modalid="OpenloginPg" modaltitle="Login"> <Login /></Modal>
      <Modal modalid="OpencreateaccPg" modaltitle="Create Account"> <CreateAccount />  </Modal>

    </Nav>
  )
}

export default NavigationBar
*/