import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom'
import FrontScreen from './Screens/FrontScreen'
import DepositScreen from './Screens/DepositsScreen'
import Forgettenpassword from './Components/ForgottenPassword'
import FooterBase from './Components/FooterBase'
import ResetPassword from './Components/ResetPassword'
import PortalRegistration from './Pages/Admin/PortalRegistration'
import StaffRegistration from './Pages/Staff/StaffRegistration'
import Chat from './chatMessenger/Chat'
import Bar1 from './Components/Bar1'
import FixedDeposits from './Pages/Deposits/FixedDeposits';
import RecurringDeposits from './Pages/Deposits/RecurringDeposits';

const App = () => {
  return (
    <>
      <ToastContainer position="bottom-center" limit={1} />
      <Bar1/>
      <Routes>
        <Route path='/' element={<FrontScreen />} />

        {/*FUNCTIONS & COMPONENTS */}
        <Route path='/forgettenpassword' element={<Forgettenpassword />} />
        <Route path='/resetpassword' element={<ResetPassword />} />

        {/* DEPOSITS */}
        <Route path='/deposits' element={<DepositScreen />} />
        <Route path='/deposits/fixed' element={<FixedDeposits />} />
        <Route path='/deposits/recurring' element={<RecurringDeposits />} />

        {/* STAFF */}
        <Route path='/staff/registration' element={<StaffRegistration />} />


        {/* ADMIN URLs */}
        <Route path='/admin/portal-registration' element={<PortalRegistration />} />

        {/*CHAT */}
        <Route path='/chat' element={<Chat />} />
      </Routes>
      <FooterBase />
    </>
  )
}

export default App