import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Store } from "../Store";
import { useNavigate } from "react-router-dom";

export default function Contacts({ contacts, changeChat}) {

  const navigate = useNavigate()


  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserImage, setCurrentUserImage] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);

  const { state } = useContext(Store);
  const { userInfo } = state;

  //console.log('contacts',contacts)

  useEffect(() => {
 //   setCurrentSelected(currentChat)
    setCurrentUserName(userInfo.firstname);
    setCurrentUserImage(userInfo.profilePicture);
  }, [userInfo]);


  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
    navigate(`?with${contact.firstname}`)

    localStorage.setItem('CurrentChat', contact.firstname)
  };

  //console.log('contact', contacts)
 // console.log({selectedContact: currentSelected, ReceivedChat:currentChat })



  return (
    <>
      {/* {currentUserImage && currentUserImage && ( */}
      <Container>
        <div className="brand">
          <img src='/assets/images/512w.png' alt="logo" />
          <h3>supachat</h3>
        </div>
        <div className="contacts">
          {contacts.map((contact, index) => {
            return (
              <div
                key={contact._id}
                className={`contact ${index === currentSelected ? "selected" : "" }`}
                onClick={() => changeCurrentChat(index, contact)}
              >
                <div className="avatar">
                  {/* <img  src={`data:image/svg+xml;base64,${contact.profilePicture}`} alt="" /> */}

                     {contact.isProfilePictureSet === false && contact.gender === 'Male' ? (
                       <img src="/assets/images/profilepic.png" alt={userInfo.firstname} style={{ minHeight: '100%', maxWidth: '60px', borderRadius: '50%' }} />)
                       : contact.isProfilePictureSet === false && contact.gender === 'Female' ? (
                         <img src="/assets/images/profilepicg.jpg" alt={contact.firstname} style={{ minHeight: '100%', maxWidth: '60px', borderRadius: '50%' }} />)
                         :
                         (<img className='img-large' src={`data:image/svg+xml;base64,${contact.profilePicture}`} alt={contact.name} style={{ minHeight: '100%', maxWidth: '100%', borderRadius: '50%' }} />)
                     }                 
                </div>
                <div className="username">
                  <h5>{contact.firstname}</h5>
                </div>
              </div>
            );
          })}
        </div>
        <div className="current-user">
          <div className="avatar">
            {/* <img src={`data:image/svg+xml;base64,${currentUserImage}`} alt="avatar" /> */}
            {userInfo.isProfilePictureSet === false && userInfo.gender === 'Male' ? (
              <img src="/assets/images/profilepic.png" alt={userInfo.firstname}  />)
              : userInfo.isProfilePictureSet === false && userInfo.gender === 'Female' ? (
                <img src="/assets/images/profilepicg.jpg" alt={userInfo.firstname} />)
                :
                (<img className='img-large' src={`data:image/svg+xml;base64,${currentUserImage}`} alt={userInfo.name} style={{ minHeight: '100%', maxWidth: '100%', borderRadius: '50%' }} />)
            }   
          </div>
          <div className="username">
            <h2>{currentUserName}</h2>
          </div>
        </div>
      </Container>
      {/* )} */}
    </>
  );
}
//background-color: #ffffff39;

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 75% 15%;
  overflow: hidden;
  background-color: #0d0d20;
  border-radius: 20px;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 2rem;
    }
    h3 {
      color: white;
      text-transform: uppercase;
    }
  }
  
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.8rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #3077ca;
      min-height: 3rem;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h5 {
          color: white;
        }
      }
    }
    .selected {
      background-color: #9a86f3;
    }
  }

  .current-user {
    background-color: #0d0d30;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .avatar {
      img {
        height: 4rem;
        max-inline-size: 100%;
      }
    }
    .username {
      h2 {
        color: white;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
`;
