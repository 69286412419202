import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//import Welcome from "./Welcome";


export default function Logout({currentChat, setCurrentChat}) {
  const navigate = useNavigate()

 // const [currentChat, SetCurrentChat] = useState(undefined);


  // const handleClick = async () => {
  //   currentChat === undefined 
  // };

  const makelogout = () => {
    navigate('/myconversations')
    localStorage.clear()
    setCurrentChat(undefined);
  };
  
  return (
    <Button onClick={makelogout }>
      x
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.3rem;
  background-color: #000;
  border: none;
  color: #fff;
  cursor: pointer;
    &:hover {
      background-color: red;
    }
  
  svg {
    font-size: 1.3rem;
    color: #ebe7ff;
  }
`;
