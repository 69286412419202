import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Store } from '../Store'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';


const Login = () => {

    useEffect(
        () => {
            document.title = 'TSBF MicroFinance - Login';
        }, []
    )

    const navigate = useNavigate()


  const [staffID, setStaffID] = useState('');
    const [password, setPassword] = useState('');

    const { dispatch: ctxDispatch } = useContext(Store);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const { data } = await Axios.post('users/signin', {
              staffID,
                password,
            });

            ctxDispatch({ type: 'USER_SIGNIN', payload: data });
            localStorage.setItem('userInfo', JSON.stringify(data));
            toast.success('Login successful!')
            //window.location.href = ('/dashboard')
            navigate('/');
        } catch (err) {
            toast.error(err);
        }
    };



    return (
        <div>
            <Container>
                <form onSubmit={submitHandler}>
                    <h4 >Login Here!</h4>
                    <div className="input-container">
                        <i className="fa fa-user icon" />
              <input className="input-field" type="text" placeholder="Staff ID" name="staffID" onChange={(e) => setStaffID(e.target.value)} required />
                    </div>
                    <div className="input-container">
                        <i className="fa fa-key icon" />
                        <input className="input-field" type="password" placeholder="Password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                    <div className='extra'>
                        <div className='text-center ' onClick={() => navigate('/chat')}><i>Don't have an account?</i> <b>Contact Admin</b>  </div>
                        <div className="text-center"><Link to='/forgettenpassword'>Forgotten password?</Link></div>
                    </div>
                </form>
            </Container>
        </div>

    )
}
//   height: 100vh;
//   
//  background-color: #131324;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  width: 100vw;

h4 {
    text-align: center;
}
.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.btn {
  background-color: dodgerblue;
  color: white;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
}

.extra {
  display: flex;
    flex-direction: row;
    padding-top: 1rem;
  gap: 9rem;
  justify-content: space-around;}

   @media screen and (max-width: 580px) {
        .extra {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            justify-content: space-around;
        }

        form {
            width: 90vw;
        }
}
`

export default Login