import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";
import ChatContainer from "./ChatContainer";
import Contacts from "./Contacts";
import Welcome from "./Welcome";
import { Store } from "../Store";


export default function Chat() {

  useEffect(
    () => {
      document.title = 'Messenger';
    }, []
  )

  const navigate = useNavigate();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);

  const { state } = useContext(Store);
  const { userInfo } = state;

  //console.log('currentChat', currentChat)
  //console.log('currentUser', currentUser)


  useEffect(() => {
    if (!localStorage.userInfo) {
      navigate("/signin");
    } else {
      setCurrentUser(userInfo);
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (currentUser) {
      socket.current = io("http://localhost:8001");
      socket.current.emit("add-user", currentUser._id);
    }
  }, [currentUser]);

  useEffect(() => {
    async function fetchContact() {
      if (currentUser) {
        const res = await axios.get(`users/mycontacts/${currentUser._id}`);
        setContacts(res.data);
      }
    }
    fetchContact()
  }, [currentUser, navigate]);

  const newChat = localStorage.getItem('CurrentChat')

  useEffect(() => {
    if (newChat) {
      setCurrentChat(newChat)
    }
  }, [])

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
    console.log('chat', chat)
    //  navigate(`/chatwith/${currentChat.firstname}`)
    //  localStorage.setItem('chat')
  };


  return (
    <>
      <Container>
        <div className="container">
          <Contacts contacts={contacts} changeChat={handleChatChange} currentChat={currentChat} />
          {currentChat === undefined ? (
            <Welcome />
          ) : (
            <ChatContainer currentChat={currentChat} setCurrentChat={setCurrentChat} socket={socket} />
          )}
        </div>
      </Container>
    </>
  );
}
// background-color: #131324;
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;

 
  .container {
    height: 85vh;
    background-image: url("/assets/images/bg2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 85vw;
    border-radius: 30px;
    background-color: #00000076;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;
