import React, { useContext, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Store } from '../Store';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';


function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const navigate = useNavigate()

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    window.location.href = '/';
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand='lg' className='p-1 justify-content-center'>
        <Container >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="me-auto justify-content-center">
              <Link to="/" className="nav-link"> Home </Link>
              <Link to="/" className="nav-link"> Live Statistics </Link>

              <NavDropdown title='Customer' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Create new customer</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Search for customer</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
              </NavDropdown>

              <NavDropdown title='Operations' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Reconciliation <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/* <span>Delete Transactions</span> */}
                </LinkContainer>

                <LinkContainer to='/profile'>
                  <NavDropdown.Item className='d-flex no-wrap'>Routine Operations &nbsp;<i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/* <span>Start of Business Day</span> */}
                  {/* <span>Close of Business Day</span> */}
                  {/* <span>End of Day</span> */}
                  {/* <span>Past Due Loan Penalties</span> */}
                  {/* <span>End of Month</span> */}

                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item >GL Transfers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>JV Transfers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Reassign Agents</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Cashback Requests</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Notices</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>E-Cash Balances</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Standing Orders</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>SMS Alerts</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Workflow <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/* <span>Delete Transactions</span> */}
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Cashier' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Deposits</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Withdrawals</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Loans' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Application <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/* Apply <i className='fa fa-angle-right float-end'></i> 
                          Regular Loan
                          Susu Loan
                          Group Loan
                  */}
                  {/* Search */}
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Appraisal <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Sanctioning <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Disbursement  <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title='Deposits'  id="basic-nav-dropdown">
                <LinkContainer to="/deposits/fixed">
                  <NavDropdown.Item>Fixed Deposits</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/deposits/recurring">
                  <NavDropdown.Item>Recurring Deposit</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>


              <NavDropdown title='Reports' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Customer <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/* Customer Listing */}
                  {/*Customer Balances */}
                  {/*Largest Deposits */}
                  {/*Customer Deposit Analysis */}
                  {/*Customer Statement*/}
                  {/* Customer Recruitment */}
                </LinkContainer>

                <LinkContainer to="/profile">
                  <NavDropdown.Item>Savings <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Susu <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Loans <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/* Loan Portfolio */}
                  {/* Loan Repayments */}
                  {/*Largest Exposures */}
                  {/* Loan Schedule */}
                  {/*Disbursed Loans*/}
                  {/* Loan Customers Analysis */}
                  {/* Aging Analysis */}
                  {/* Past Due Loans */}
                  {/* Expired Loans */}
                  {/* Rejected Loans */}
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>GL <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/*GL Transactions*/}
                  {/* GL Movements*/}
                  {/* GL Balances */}
                  {/* Income*/}
                  {/* Expenses */}
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Regulatory Reports <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/*Weekly Return on Liquidity Reserves */}
                  {/* Quarterly Return on Financial Position: Assets, Liabilities and Own Funds */}
                  {/*Quarterly Return On Current Year Operating Result */}
                  {/* Quarterly Report On Deposits Exceeding 5% Of Paid Capital */}
                  {/*Loans and Advances*/}
                  {/* Other Assets */}
                  {/*Borrowings*/}
                  {/*Deposits From the Public */}
                  {/* Other Liabilities */}
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Misc. <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Administration' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Configuration <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Administration <i className='fa fa-angle-right float-end'></i></NavDropdown.Item>
                  {/*  Users <i className='fa fa-angle-right float-end'></i>
                                Staff Profiles
                                Inactive Staff Profiles
                        Susu Customers Classification Definition
                        Assign Agents
                        Assign Branches
                        Assign GL To Users
                  */}
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Settings' id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>User Profile</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <Link className="nav-link" to="#signout" onClick={signoutHandler} > Logout  </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>

  );
}

export default App;