import React from 'react'

const DepositsScreen = () => {
    return (
        <>
            <div className="App">

            Home / Deposits Page
            <div>Deposits</div>
            <div className='d-flex flex-row justify-content-space-evenly'>
                <button>Fixed</button>
                <p>Or</p>
                <button>Recurring</button>
            </div>
</div>
        </>
    )
}

export default DepositsScreen